
  import { getChargeServicesList } from '~/api/goods'
  import { State, Getter, Action, Mutation, namespace } from "vuex-class";
  import { Component, Vue, Emit, Prop} from 'vue-property-decorator'
  @Component({
    name:"chargingstandard",
    components: {
     
    },
  })
    export default class chargingstandard extends Vue {
      @Prop({
        default: () => {
        }
      }) goodsInfo_?: any

    public selectedIndexa:number = 0
    public feestandard:any = []
    public datalist:any = [] 

    created() {
      
    }
    mounted() {
      this.Qurygemg()
      console.log(this.goodsInfo_,'+++++++++++++++++++++++++商品详情123');
     
    }

    

    selectedIndex(index:any) {
			this.selectedIndexa = index;

      console.log(index,'+++++++++++++++++++');
	
		}

		cancel(){
			this.$emit('cancel')

		}


    @Emit()
 async Qurygemg(){
    let res = await getChargeServicesList({ params: { 
      isAll: 1 ,
      charge_type:this.selectedIndexa,
      gameId:this.goodsInfo_.gameId,
    } })
    
    if(res?.data.code == 0){
    this.feestandard = res.data.data.data.filter( (item:any) =>{
      return item.DataJson = JSON.parse(item.DataJson)
    })
    
    this.datalist = this.feestandard[0]
    

    console.log(this.datalist.DataJson,'++++++++++++++++++++++++收费');
    
    }

  }

        
    }



